.marginAuto {
  margin: auto;
}

.rFlex {
  display: flex !important;
  flex-direction: row !important;
}

.cFlex {
  display: flex !important;
  flex-direction: column !important;
}

.centreAllFlex {
  align-items: center !important;
  justify-content: center !important;
}

.alignCentre {
  align-items: center !important;
}

.centreFlex {
  justify-content: center !important;
}

.wrap {
  flex-wrap: wrap;
}

.spacebetween {
  justify-content: space-between !important;
}

.spacearound {
  justify-content: space-around !important;
}

.spaceevenly {
  justify-content: space-evenly !important;
}
.alignselfstart {
  align-self: start;
}

.centre {
  text-align: center;
}

.textaligncenter {
  text-align: center;
}

.mat-tooltip-trigger {
  touch-action: auto !important;
}

.desc-box-highlight {
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  &:hover {
    background: #eef1f3;
    -webkit-box-shadow: 0px 0px 0px 8px #eef1f3;
    box-shadow: 0px 0px 0px 8px #eef1f3;
  }

  * {
    max-width: 100%;
  }
}
div {
  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 12px;
  }
}

.angular-editor-textarea {
  min-height: 20px !important;
}

// Important! Keep this. Helps to keep the height of elements that would be hidden otherwise on small screens after Angular 18 upgrade
.mat-mdc-dialog-content {
  min-height: 20px;
}
